import styles from './PostTags.module.css'
import BreadCrumbs from 'shared-components/ui/BreadCrumbs'
import Linked from 'shared-components/service/Linked'
import clsx from 'clsx'
import { BasicStyledComponent, DSPostMeta } from 'shared-definitions/types'
import { AppEventsContext } from '../contexts/AppEventsContext'

interface PostTagsProps extends BasicStyledComponent {
  meta: Pick<DSPostMeta, 'categories' | 'tags'>
}

const PostTags: React.VFC<PostTagsProps> = ({ className, meta }) => {
  const { appEvent } = AppEventsContext.useContainer()
  return (
    <div className={clsx(styles.container, className)}>
      <BreadCrumbs list={meta.categories} place="bottom" />

      <div className={styles.tagList}>
        {meta.tags.map((item, index) => (
          <Linked
            key={index}
            className={styles.tag}
            {...item.pLink}
            onClick={() => {
              appEvent({
                event: 'page-tag-clicked',
                type: 'tag',
                label: item.label,
                url: item.pLink.href,
              })
            }}
          >
            {item.label}
          </Linked>
        ))}
      </div>
    </div>
  )
}

export default PostTags
