import styles from './TopBanner.module.css'
import PlaywireTagged from 'shared-components/ad/PlaywireTagged'
import { PostContext } from 'shared-components/contexts/PostContext'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'

const TopBanner: React.VFC = () => {
  const { disabledAds } = PostContext.useContainer()
  const { enabled } = PlaywireContext.useContainer()
  if (disabledAds) {
    return null
  }
  if (!enabled) {
    return null
  }
  return (
    <div className={styles.topLeaderboard}>
      <PlaywireTagged type="leaderboard_atf" />
    </div>
  )
}

export default TopBanner
