/* eslint-disable react/display-name */
import styles from './ContentLayout.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { usePageBodyLayout } from 'shared-components/contexts/PageBodyContext'

type ContentLayoutProps = BasicStyledComponent & { children: React.ReactNode }

const ContentLayout: React.FC<ContentLayoutProps> = ({ className, children }) => (
  <div ref={usePageBodyLayout()} className={clsx(styles.container, className)}>
    {children}
  </div>
)

export default ContentLayout
