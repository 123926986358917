import styles from './SidebarFloatingAd.module.css'
import { publicConfig } from 'config'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { PostContext } from 'shared-components/contexts/PostContext'
import SidebarWithStickyAds from 'shared-components/layout/SidebarWithStickyAds'
import PlaywireTagged from 'shared-components/ad/PlaywireTagged'
import { ResponsiveContext } from 'shared-components/contexts/ResponsiveContext'
import { useCallback } from 'react'

type SidebarFloatingAdProps = BasicStyledComponent

const SidebarFloatingAd: React.VFC<SidebarFloatingAdProps> = ({ className }) => {
  const { disabledAds } = PostContext.useContainer()
  const { ready, lg } = ResponsiveContext.useContainer()

  const render = useCallback(
    (index: number) => <PlaywireTagged type={index === 0 ? 'med_rect_atf' : 'med_rect_btf'} />,
    []
  )

  // also disable ads on mobile screens
  if (disabledAds || !(ready && lg)) {
    return null
  }

  return (
    <div className={clsx(styles.container, className)}>
      <div className={publicConfig('sidebarAdVideoClass')}>
        <div />
      </div>

      <SidebarWithStickyAds render={render} />
    </div>
  )
}

export default SidebarFloatingAd
