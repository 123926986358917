/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import { DSTeamMember } from 'shared-definitions/types'
import styles from './PostedByFull.module.css'

const AuthorHint = dynamic(() => import('shared-components/ui/AuthorHint'))

interface PostedByFullProps {
  prefix: string
  authors: DSTeamMember[]
  buttonClassName?: string
}

const PostedByFull: React.VFC<PostedByFullProps> = ({ prefix, buttonClassName, authors }) => {
  const [activated, setActivated] = useState<Record<string, boolean>>({})

  return (
    <div className={buttonClassName}>
      <span>{`${prefix} `}</span>
      {authors.map((item, index) => (
        <button
          type="button"
          className={clsx(buttonClassName, styles.button)}
          key={index}
          onMouseOver={() => setActivated(s => ({ ...s, [item.pLink.href]: true }))}
        >
          {item.name}
          {activated[item.pLink.href] ? <AuthorHint author={item} className={styles.hint} /> : null}
        </button>
      ))}
      <div className={styles.fadeLayer} />
    </div>
  )
}

export default PostedByFull
