import styles from './PostTitle.module.css'
import stylePost from 'shared-components/styles/post.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'

interface PostTitleProps extends BasicStyledComponent {
  title: string
  subtitle: string | null
}

const PostTitle: React.VFC<PostTitleProps> = ({ className, title, subtitle }) => (
  <div className={className}>
    <h1 className={stylePost.pageTitle}>{title}</h1>
    {subtitle ? (
      <div className={clsx(styles.subtitle, stylePost.pageSubTitle)}>{subtitle}</div>
    ) : null}
  </div>
)

export default PostTitle
