import { useEffect } from 'react'
import { ProductBarProps } from 'shared-definitions/types'
import { AffilateContext } from 'shared-components/contexts/AffilateContext'

type UseAffilateOpts = ProductBarProps[]

export function useAffilate(bars: UseAffilateOpts): void {
  const { setState } = AffilateContext.useContainer()
  useEffect(() => {
    setState(s => ({ ...s, bars }))
    return () => {
      setState(s => ({ ...s, bars: [] }))
    }
  }, [bars, setState])
}
