import clsx from 'clsx'
import { AffilateContext } from 'shared-components/contexts/AffilateContext'
import styles from './PostNavigation.module.css'
import { DealBarContext } from 'shared-components/contexts/DealBarContext'
import { ScrollUpContext } from 'shared-components/contexts/ScrollUpContext'
import { PostContext } from 'shared-components/contexts/PostContext'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'

const PostNavigation: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { sections } = PostContext.useContainer()
  const { bars, activeIndex, visible } = AffilateContext.useContainer()
  const bestBuyItem = bars[activeIndex] ?? null
  const { dealbar, hiddenOnMobile: dealbarHiddenOnMobile } = DealBarContext.useContainer()
  const { scrolledUp } = ScrollUpContext.useContainer()
  const { showAdminPanel } = CommonDataContext.useContainer()
  const dealbarSticky = dealbar?.sticky === true

  return (
    <div
      className={clsx(styles.container, {
        [styles.showStickyDealbar]: !dealbarHiddenOnMobile && scrolledUp && dealbarSticky,
        [styles.hasAffilateBar]: bestBuyItem && visible,
        [styles.dealbarHiddenOnMobile]: dealbarHiddenOnMobile,
        [styles.dealbarSticky]: dealbar?.sticky ?? false,
        [styles.headerVisible]: scrolledUp,
        [styles.hasSections]: sections !== null,
        [styles.previewMode]: showAdminPanel,
      })}
    >
      {children}
    </div>
  )
}

export default PostNavigation
