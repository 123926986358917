import clsx from 'clsx'
import PostSocials from 'shared-components/ui/PostSocials'
import PostedByFull from 'shared-components/ui/PostedByFull'
import {
  BasicStyledComponent,
  DSAuthored,
  DSPostMeta,
  DSSocialIcon,
} from 'shared-definitions/types'
import styles from './PostShortLine.module.css'

interface PostProps {
  pagePath: string | null
  title: string
  subtitle: string | null
  meta: DSPostMeta
  socials?: readonly DSSocialIcon[]
  authors: DSAuthored
  firstPublishedDate: string | null
  date: string
}

interface PostShortLineProps extends BasicStyledComponent {
  post: PostProps
}

const PostShortLine: React.VFC<PostShortLineProps> = ({ className, post }) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.postedByContainer}>
      {post.meta.sponsor ? (
        <div className={styles.postedBy}>{post.meta.sponsor}</div>
      ) : (
        <>
          <PostedByFull
            prefix="By"
            authors={post.authors.authoredBy}
            buttonClassName={styles.postedBy}
          />
          {post.authors.updatedBy.length > 0 && (
            <>
              <div className={styles.dateDot}>{'•'}</div>
              <PostedByFull
                prefix="Updated by:"
                authors={post.authors.updatedBy}
                buttonClassName={styles.postedBy}
              />
            </>
          )}
          {post.authors.reviewedBy.length > 0 && (
            <>
              <div className={styles.dateDot}>{'•'}</div>
              <PostedByFull
                prefix="Reviewed by:"
                authors={post.authors.reviewedBy}
                buttonClassName={styles.postedBy}
              />
            </>
          )}
        </>
      )}
      <div className={styles.dateDot}>{'•'}</div>
      <p className={styles.date}>
        {/**
         * this span is a hack, which will possible allow Google
         * to pick right published date, it is hidden from actual
         * content by display: none
         */}
        <span className={styles.datePrefix}>{'Published on'}</span>
        {post.date}
      </p>
      {post.firstPublishedDate ? (
        <>
          <div className={styles.dateDot}>{'•'}</div>
          <div className={styles.date}>{`Originally published on ${post.firstPublishedDate}`}</div>
        </>
      ) : null}
    </div>
    {post.pagePath ? (
      <PostSocials title={post.title} socials={post.socials ?? []} absoluteLink={post.pagePath} />
    ) : null}
  </div>
)

export default PostShortLine
