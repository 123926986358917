import { useEffect, useRef } from 'react'
import { ReadingProgressContext } from 'shared-components/contexts/ReadingProgressContext'
import { PostContext } from 'shared-components/contexts/PostContext'
import { AppEventsContext } from '../contexts/AppEventsContext'

const ScrollEventsListener: React.VFC = () => {
  const postMeta = PostContext.useContainer()
  const { appEvent } = AppEventsContext.useContainer()
  const { progress } = ReadingProgressContext.useContainer()
  const frame = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const handler = (): void => {
      appEvent({
        event: 'page-scrolled',
        progress,
        postMeta,
      })
      frame.current = null
    }

    if (frame.current) {
      clearTimeout(frame.current)
    }
    frame.current = setTimeout(handler, 100)
  }, [postMeta, progress])

  return null
}

export default ScrollEventsListener
