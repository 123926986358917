import NC from 'nc/NC'
import TopBanner from 'shared-components/ad/TopBanner'
import stylesCommon from './EventTemplate.TextTemplate.module.css'
import ContentLayout from 'shared-components/ui/ContentLayout'
import styleUtils from 'shared-components/styles/util.module.css'
import { DSTextPage } from 'shared-definitions/types'
import { DSNCAA } from 'types.aa.ds'

interface TextTemplateProps {
  page: DSTextPage<DSNCAA>
}
const TextTemplate: React.VFC<TextTemplateProps> = ({ page }) => (
  <>
    <TopBanner />
    <div className={styleUtils.wrapper}>
      <ContentLayout className={stylesCommon.layout}>
        {page.blocks.map((item, index) => (
          <NC key={index} data={item} tpl="text" index={index} />
        ))}
      </ContentLayout>
    </div>
  </>
)

export default TextTemplate
