import styles from './EventHero.module.css'
import AppImage from 'shared-components/ui/AppImage'
import PostTitle from 'shared-components/ui/PostTitle'
import clsx from 'clsx'
import { BasicStyledComponent, DSImage } from 'shared-definitions/types'
import viewport from 'shared-definitions/viewport.json'

interface PostProps {
  image: DSImage
  title: string
  subtitle: string | null
}

interface EventHeroProps extends BasicStyledComponent {
  post: PostProps
}

const EventHero: React.VFC<EventHeroProps> = ({ className, post }) => (
  <div className={clsx(styles.container, className)}>
    {post.image ? (
      <AppImage
        image={post.image}
        layout="responsive"
        sizes={[{ bp: 'lg', val: viewport['lg'] }, 'calc(100vw - rem(60))']}
        imageClassName={styles.image}
      />
    ) : null}
    <PostTitle title={post.title} subtitle={post.subtitle} />
  </div>
)

export default EventHero
