import styles from './ThreeColumnLayout.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'

export interface ThreeColumnLayoutProps extends BasicStyledComponent {
  content: React.ReactNode
  sidebar: React.ReactNode
  left: React.ReactNode
}

const ThreeColumnLayout: React.VFC<ThreeColumnLayoutProps> = ({
  className,
  content,
  sidebar,
  left,
}) => (
  <div className={clsx(styles.container, className)}>
    <aside className={styles.left}>{left}</aside>
    <div className={styles.content}>{content}</div>
    <aside className={styles.sidebar}>{sidebar}</aside>
  </div>
)

export default ThreeColumnLayout
