import ThreeColumnLayout, { ThreeColumnLayoutProps } from 'shared-components/ui/ThreeColumnLayout'
import SidebarLayout from 'shared-components/ui/SidebarLayout'

type AdaptiveThreeColumnLayoutProps = ThreeColumnLayoutProps

const AdaptiveThreeColumnLayout: React.VFC<AdaptiveThreeColumnLayoutProps> = props => {
  if (!props.left) {
    return <SidebarLayout {...props} />
  }
  return <ThreeColumnLayout {...props} />
}

export default AdaptiveThreeColumnLayout
