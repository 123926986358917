import { BasicStyledComponent, DSSocialIcon } from 'shared-definitions/types'
import { useCopy } from 'shared-components/hooks/use-copy'
import SocialIcon from 'shared-components/service/SocialIcon'
import Linked from 'shared-components/service/Linked'
import clsx from 'clsx'
import styles from './PostSocials.module.css'
import { socialShare } from 'shared-code/social'
import Copy from 'shared-svg/Copy.svg'

interface PostSocialsProps extends BasicStyledComponent {
  absoluteLink: string
  title: string
  socials: readonly DSSocialIcon[]
}

const PostSocials: React.VFC<PostSocialsProps> = ({ absoluteLink, title, socials, className }) => {
  const { copy } = useCopy()

  return (
    <div className={clsx(styles.container, className)}>
      <button
        aria-label="Copy"
        type="button"
        className={styles.link}
        onClick={() => copy(absoluteLink)}
      >
        <Copy className={styles.icon} />
      </button>
      {socials
        ? socials.map(item => {
            const sharedLink = socialShare(item, absoluteLink, title)
            if (!sharedLink) {
              return null
            }
            return (
              <Linked
                target="_blank"
                className={styles.link}
                href={sharedLink}
                key={item}
                title={item}
              >
                <SocialIcon className={styles.icon} icon={item} />
              </Linked>
            )
          })
        : null}
    </div>
  )
}

export default PostSocials
