import clsx from 'clsx'
import { Fragment, FC } from 'react'
import Linked from 'shared-components/service/Linked'
import { BasicStyledComponent, DSLabeledLink } from 'shared-definitions/types'
import ArrowRightShadeSmall from 'shared-svg/ArrowRightShadeSmall.svg'
import { AppEventsContext } from '../contexts/AppEventsContext'
import styles from './BreadCrumbs.module.css'

interface BreadCrumbsProps extends BasicStyledComponent {
  list: readonly DSLabeledLink[]
  place?: 'top' | 'bottom'
}

const BreadCrumbs: FC<BreadCrumbsProps> = ({ className, list, place = 'top' }) => {
  const { appEvent } = AppEventsContext.useContainer()

  if (!list.length) {
    return null
  }
  return (
    <div className={clsx(styles.container, className)}>
      {list.map((item, index) => (
        <Fragment key={index}>
          {index ? <ArrowRightShadeSmall className={styles.arrow} /> : null}
          <Linked
            {...item.pLink}
            className={clsx(styles.link, {
              [styles.lastLink]: index === list.length - 1,
            })}
            onClick={() => {
              appEvent({
                event: 'page-tag-clicked',
                type: place === 'top' ? 'top-category' : 'bottom-category',
                label: item.label,
                url: item.pLink.href,
              })
            }}
          >
            {item.label}
          </Linked>
        </Fragment>
      ))}
    </div>
  )
}

export default BreadCrumbs
