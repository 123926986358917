import styles from './SidedNav.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSToggledMenuItem } from 'shared-definitions/types'
import Linked from 'shared-components/service/Linked'
import ArrowRightShade from 'shared-svg/ArrowRightShade.svg'
import WavyLine from 'shared-svg/WavyLine.svg'
import React from 'react'

type Render = (el: React.ReactElement, item: DSToggledMenuItem, key: number) => React.ReactNode

export interface SidedNavProps extends BasicStyledComponent {
  title: string
  menu: DSToggledMenuItem[]
  renderItem?: Render
}

const SidedNav: React.VFC<SidedNavProps> = ({ renderItem, className, title, menu }) => {
  if (!menu.length) {
    return null
  }
  return (
    <div className={clsx(styles.container, className)}>
      <h4 className={styles.title}>{title}</h4>
      <WavyLine className={styles.wavy} />

      {menu.map((item, index) => {
        const render: Render = renderItem ?? (i => i)

        const el = (
          <Linked
            {...item.pLink}
            key={index}
            className={clsx(styles.navLink, { [styles.active]: item.active })}
          >
            {item.active ? <ArrowRightShade className={styles.iconArrow} /> : null}
            {item.label}
          </Linked>
        )

        return render(el, item, index)
      })}
    </div>
  )
}

export default SidedNav
