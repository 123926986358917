/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable react/jsx-no-bind */
import styles from './Comments.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { useState } from 'react'
import ArrowBottomBird from 'shared-svg/ArrowBottomBird.svg'
import IconedButton from 'shared-components/buttons/IconedButton'
import { disqusIdentifier } from 'shared-code/util'
import dynamic from 'next/dynamic'
import { PostContext } from 'shared-components/contexts/PostContext'

interface DiscussionEmbedProps {
  shortname: any
  config: any
}
const DiscussionEmbed = dynamic<DiscussionEmbedProps>(
  () => import('disqus-react').then(m => m.DiscussionEmbed),
  {
    ssr: false,
  }
)

interface CommentsProps extends BasicStyledComponent {
  appId: string
  buttonClassName?: string
}

const Comments: React.VFC<CommentsProps> = ({ className, appId, buttonClassName }) => {
  const { postId: id, pagePath: absoluteLink, title } = PostContext.useContainer()
  const { sponsored } = PostContext.useContainer()
  const [opened, setOpened] = useState(false)

  if (sponsored || !id) {
    return null
  }

  if (!opened) {
    return (
      <IconedButton onClick={() => setOpened(true)} className={clsx(className, buttonClassName)}>
        {'Comments'}
        <ArrowBottomBird className={styles.buttonIcon} />
      </IconedButton>
    )
  }

  return (
    <div className={clsx(styles.container, className)}>
      <DiscussionEmbed
        shortname={appId}
        config={{
          url: absoluteLink,
          identifier: disqusIdentifier(id.toString()),
          title,
        }}
      />
    </div>
  )
}

export default Comments
